@mixin transform($property) {
    -moz-transform: $property;
      -o-transform: $property;
     -ms-transform: $property;
 -webkit-transform: $property;
       transform: $property;
}
@mixin transition($what: all, $time: 0.4s, $how: linear) {
    -webkit-transition: $what $time $how;
    -moz-transition: $what $time $how;
    -ms-transition: $what $time $how;
    -o-transition: $what $time $how;
    transition: $what $time $how;
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-o-placeholder          {@content}
    :-ms-input-placeholder      {@content}   
}
@mixin animation($property) {
    -moz-transform: $property;
      -o-transform: $property;
     -ms-transform: $property;
 -webkit-transform: $property;
       transform: $property;
}

// For Opacity
@mixin opacity($value) {
    $IEValue: $value*100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    filter: alpha(opacity=$IEValue);
  }
  