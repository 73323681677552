/*--------------------------------------------------------------------------
Template name: Foodor
Template URI: https://foodor.netlify.com
Author Name: Tortoiz
Author URI: 
Tag: Restaurants, Food, Hotel, Food Business
Verson: 1.0.0
Description: Foodor is a responsive html5 bootstrap5 Restaurants Template    

===========================
CSS Index
===========================

01. Header Area
02. Hero Banner Area 
03. Features Area
04. Order Step
05. Foods Tabs Area
06. Call To Action Area
07. Popular Items
08. Clients Reviews Carousel
09. Apps Features Area
10. Subscribe Area
11. Footer Area
12. About Us Page
13. Services Page
14. Menu Page
15. Product Details Page
16. Blog Details
17. Checkout 
18. Track Order
19. Account 
20. Login Page



------------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Playfair+Display:wght@700;900&display=swap');
@import "partials/variables";
@import "partials/default";
@import "partials/media_query";
@import "partials/prefix";


/* **************************************
01. Header Area
****************************************/
.header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;

    @include mobile {
        background: #ffe0c9;
        padding: 10px 0;

        .navbar-nav.ms-auto {
            padding-top: 30px;
        }
    }
}

.primary-navigation {
    position: relative;
    width: 100%;
    margin: 0;
    z-index: 999;
    top: 0;
    transition: all 0.3s ease;

    .default-btn-alt {
        padding: 12px 45px;
    }
}

.navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;

    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.navbar-light .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid $black;
    transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: $black;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
    top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

.navbar.navbar-light {
    padding: 0;

    .nav-item {
        position: relative;

        .nav-link {
            position: relative;
            padding: 30px 20px;
            display: inline-block;
            font-family: $default_font;
            font-weight: 500;
            font-size: 15px;
            color: $default_color;

            @include desktop {
                font-size: 14px;
                padding: 28px 12px;
            }

            @include mobile {
                padding: 12px 20px;
            }
        }

        &:hover a.nav-link,
        &:focus .nav-link {
            color: $default_color;
        }

        .dropdown-menu {
            position: absolute;
            left: 0;
            padding: 15px 5px;
            top: 120%;
            background: $white;
            border: none;
            border-radius: 0px;
            box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, .15);
            min-width: 200px;
            display: block;
            opacity: 0;
            border-radius: 5px;
            visibility: hidden;
            transition: all 300ms linear;

            .dropdown-item {
                padding: 7px 15px;
                color: $default_color;
                font-size: 14px;
                transition: all 300ms linear;
                font-weight: 500;

                &:hover,
                &:focus {
                    color: $hover;
                    padding-left: 25px;
                    background: transparent;
                }

            }
        }

        &.login-item {
            a {
                color: $white;

                i {
                    margin-right: 2px;
                }
            }
        }

        &:hover>.dropdown-menu {
            opacity: 1;
            top: 100%;
            visibility: visible;
            max-height: 999px;
            transform: translate3d(0, 0px, 0);
        }

    }

    .dropdown-toggle::after {
        border: none;
    }

}

li.nav-item ul.dropdown-menu li.dropdown .dropdown-menu {
    left: 100%;
    top: 100%;

    @include mobile {
        left: 55%;
    }

    &.dropdown-right {
        left: auto;
        right: 100%;
    }
}

li.nav-item ul.dropdown-menu li.dropdown:hover>.dropdown-menu {
    display: block;
    visibility: visible;
    max-height: 999px;
    top: 0;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
}

/* *******************************
MINI CART */
li.nav-item.mini-cart {
    position: relative;

    a.nav-link i {
        font-size: 20px;
    }

    sup.cart-quantity {
        background: white;
        padding: 10px 6px;
        border-radius: 50%;
        display: inline-block;
        font-weight: 600;
        font-size: 12px;
        top: -10px;
    }

    .mini-cart-box {
        height: 350px;
        padding: 5px 20px 20px 20px;
        overflow-y: auto;
        width: 300px;
        position: absolute;
        right: 0;
        top: 120%;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        background-color: #fff;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;

        .cart-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-bottom: 1px solid #ededed;
            padding: 20px 0;
            text-align: left;
            width: 100%;
            overflow: hidden;
            position: relative;

            .thumb {
                float: left;
                width: 30%;
                margin-right: 20px;
                position: relative;
            }

            .content {
                float: left;
                display: inline-table;
                width: 60%;

                .title {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    text-overflow: ellipsis;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 123px;

                    a {
                        color: #222;

                        &:hover {
                            color: $hover;
                        }
                    }
                }

                .product-quantity {
                    color: #222;
                    font-size: 16px;
                    display: block;
                    margin-bottom: 8px;
                }

                .product-price {
                    color: $hover;
                    display: block;
                    font-size: 16px;
                }

                .cart-trash {
                    position: absolute;
                    color: #222;
                    right: 2px;
                    top: 18px;
                    display: inline-block;
                    left: auto;
                    font-size: 15px;
                }
            }
        }

        .cart-total-money {
            border-bottom: 1px solid #ededed;
            text-align: left;
            padding: 20px 0;
            overflow: hidden;

            h4 {
                color: #3e3d43;
                font-size: 17px;
                font-weight: 600;
                margin-bottom: 0;

                .money {
                    float: right;
                }

            }
        }

        .cart-btn {
            margin-top: 20px;

            a {
                background-color: #333;
                color: #fff;
                display: block;
                font-size: 16px;
                font-weight: 600;
                padding: 10px 18px;
                text-align: center;

                &:hover {
                    background-color: $hover;
                    color: #fff;
                }

                &:last-child {
                    margin-top: 10px;
                }
            }
        }

    }

    &:hover .mini-cart-box {
        top: 100%;
        opacity: 1;
        visibility: visible;
    }
}

/**************************
02. Hero Banner Area 
********************************/
.hero-area-wrapper {
    display: flex;
    padding: 120px 0 0;
    position: relative;

    .hero-bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 73%;
        height: 100%;
        background-size: cover;
        z-index: -1;

        @include mobile {
            display: none;
        }
    }

    .heading-one {
        font-size: 4.8rem;
        font-family: $second_font;
        font-weight: 900;
        margin-bottom: 15px;

        @include mobile {
            font-size: 30px;
        }

        @include desktop {
            font-size: 2.8rem;
        }

        @include large {
            font-size: 3.8rem;
        }
    }

    p {
        font-weight: 500;
        margin: 10px 0 30px;
    }
}

.input-wrapper {
    position: relative;

    i {
        position: absolute;
        top: 35%;
        left: 5px;
        color: $hover;
    }

}

.location-form {
    border-radius: 30px;
    margin: 40px 28px 0 0;
    position: relative;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);
    max-width: 90%;

    @include large {
        max-width: 100%;
        margin-right: 0;
    }

    @include desktop {
        max-width: 100%;
        margin-right: 0;
    }

    @include mobile {
        max-width: 100%;
        margin-right: 0;
    }

    .form-content>div:nth-child(1) {
        width: 120px;
    }

    .input-wrapper.input-btn {
        position: absolute;
        right: 0;

        @include large {
            right: -5%;
        }

        @include desktop {
            right: -18%;
        }
    }

    @include large {
        margin-right: 0;
    }

    .nice-select {
        border: none;
        background-color: transparent;
        width: 100%;

        span.current {
            color: #5a5b67;
        }
    }

    .form-content {
        min-height: 45px;
        background-color: $white;
        border-radius: 30px;
        align-items: center;
        display: flex;

        .form-control {
            border-color: transparent;
            font-size: 14px;
            padding: 10px 25px;

            @include tablet {
                font-size: 12px;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .default-btn {
            font-size: 15px;
            padding: 13px 30px;

            @include mobile {
                padding: 13px 20px;
            }
        }
    }
}

/*************************
03. Features Area
****************************/
.single-feature {
    border-radius: 100px;
    cursor: pointer;

    @include mobile {
        margin-bottom: 30px;
    }

    @include desktop {
        margin-bottom: 30px;
    }

    @include tablet {
        margin-bottom: 30px;
    }

    .feature-icon {
        display: inline-block;
        margin-right: 12px;
        height: 60px;
        width: 60px;
        border: 1px solid #0c0f2e;
        border-radius: 100%;
        padding: 15px;
        text-align: center;
        line-height: 34px;
        @include transition();
    }

    .feature-text {
        display: inline-block;

        h6 {
            font-size: 1.375rem;
        }
    }

    &:hover {
        .feature-icon {
            border: 1px solid $hover;
        }

        h6 {
            color: $hover;
        }
    }
}

/***************************
04. Order Step
***************************/
.order-step-area {
    .single-step {
        @include mobile {
            margin-bottom: 45px;
        }

        h6 {
            margin-bottom: 30px;
        }

        img {
            @include desktop {
                max-width: 100%;
            }

            @include tablet {
                max-width: 100%;
            }

            @include mobile {
                max-width: 100%;
            }
        }
    }
}

/**************************
05. Foods Tabs Area
***************************/
.menu-tab.nav-pills {
    @include desktop {
        max-width: 50%;
    }

    a {
        display: flex;
        align-items: center;
        transition: all .2s linear 0s;
        border-radius: 50px !important;
        padding: 14px 20px;
        background-color: transparent;
        margin-bottom: 25px;

        .heading-6 {
            @include transition();
        }

        &.active,
        &:hover {
            border-color: transparent !important;
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
            background-color: $white;

            .heading-6 {
                color: $hover;
            }

            .tab-icon {
                border-color: $hover;
            }
        }

        .tab-icon {
            padding: 8px;
            display: inline-block;
            border-radius: 50%;
            width: 69px;
            height: 69px;
            border: 1px solid $default_color;
            margin-right: 15px;
            text-align: center;
            @include transition();

            img {
                height: 40px;
                margin-top: 4px;
            }
        }

        &:hover {
            border-color: transparent !important;
        }
    }
}

.food-tab-content {
    .card-img-top {
        border-radius: 10px;
    }

    .product-card {
        transition: all .4s linear 0s;

        &:hover {
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
        }
    }
}

.product-card {
    margin-bottom: 20px;
    @include transition;
    border-radius: 15px;

    .card-meta {
        color: $hover;
        font-weight: 600;

        .product-price {
            margin-left: 15px;

            span {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    .rating-point {
        color: $default_color;
        padding-left: 5px;
    }

    &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, .1);
        transform: translateY(-5px);

        .black-overlay:before {
            opacity: 1;
            visibility: visible;
        }

        .sm-btn {
            opacity: 1;
            visibility: visible;
        }
    }
}

.card-thumb {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    z-index: 1;

    .sm-btn {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 45%;
        transform: translate(-50%);
        transition: all .4s linear 0s;
        opacity: 0;
        visibility: hidden;
        border: none;
    }

}

.products-wrapper {
    .product-card {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, .05);

        &:hover {
            box-shadow: 0px 5px 25px rgba(0, 0, 0, .1);
        }
    }
}

.popular-posts-wrapper-2 {
    .popular-item {
        margin: 10px 0;
    }
}

.single-pricing {
    .nice-select {
        height: 30px;
        padding-left: 18px;
        padding-right: 28px;
        line-height: 30px;
    }
}

.popular-item .card-thumb {
    line-height: .5;

    a.sm-btn {
        padding: 12px 20px;
    }
}


.about-section-area.section-ptb {
    padding-bottom: 160px;
    .section-title-3::after {
        left: 0;
    }
    @include mobile {
        padding-bottom: 75px;
    }

    .section-thumb {
        @include desktop {
            max-width: 60%;
            margin: 0 auto 10px;
        }
    }
}

.single-countdown {
    padding: 40px 15px 20px;
    border-radius: 25px;

    @include mobile {
        margin-bottom: 30px;

        h3 {
            font-size: 27px;
        }
    }

    h3 {
        color: $hover;
        font-weight: 600;
    }

    p {
        font-weight: 500;
        font-size: 1.2rem;
    }
}

/***********************************
06. Call To Action Area
**************************/
.call-to-action-area {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    h2 {
        line-height: 65px;
        font-weight: 700;

        @include mobile {
            line-height: 1.4;
        }
    }

    .default-btn-2 {
        padding: 12px 42px;
    }

    .call-to-action-thumb {
        position: absolute;
        right: 0;
        top: -50px;

        @include desktop {
            right: 0px;
            top: 30%;
            width: 60%;
        }

        @include mobile {
            position: absolute;
            right: 0;
            top: 65%;
            width: 60%;
        }
    }
}

.call-to-action-content {
    padding: 60px 0;
}

.popular-items-area.section-ptb {
    padding-top: 130px;

    .popular-item {
        margin: 10px 0;
    }
}

/**********************
07. Popular Items
*************************/
.carousel-style-one {
    .owl-dots {
        text-align: center;
        margin-top: 15px;

        button {
            border: 1px solid $hover;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 5px;

            &:focus {
                outline: 0;
            }
        }

        .owl-dot {
            transition: all .4s linear 0s;

            &.active {
                background-color: $hover;
                width: 30px;
                border-radius: 35px;
            }
        }
    }

    &.reviews-wrapper {
        .owl-dots {
            margin-top: 0;
        }
    }
}

/**********************************
08. Clients Reviews Carousel
**************************/

.client-info {
    display: flex;
    align-items: center;

    .heading-6-alt {
        font-family: $default_font;
        margin-bottom: 5px;
    }
    .client-thumb {
        overflow: hidden;
        margin-right: 10px;
        border-radius: 50%;
        border: 2px solid $white;
        margin-bottom: 10px;
    }
}

.review-icons {
    display: flex;
    justify-content: space-between;
}

.star-icons {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
        }
    }

    i {
        color: #fba13a;
        font-size: 14px;
    }
}

.quote-icons {
    i {
        font-size: 55px;
        color: $hover;
        opacity: 0.2;
    }
}


.reviews-wrapper {
    .owl-item {
        padding: 15px;
    }

    .single-review {
        border-radius: 15px;
        padding: 30px;
        background-color: $white;
        @extend .shadow;
    }

    .center {
        .single-review {
            background-color: $hover !important;
            color: $white;

            .star-icons {
                i {
                    color: $white;
                }
            }

            .quote-icons {
                i {
                    color: $white;
                }
            }
        }

        .client-bio {
            p {
                color: $white;
            }
        }

        .heading-6-alt {
            color: $white;
        }
    }

    .client-bio {
        p {
            color: $hover;
            transition: all .4s linear 0s;
        }
    }
}

.single-blog {
    h3 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 34px;

        @include mobile {
            font-size: 1.3rem;
            line-height: 28px;
        }

        a {
            color: $default_color;
            text-decoration: none;

            &:hover {
                color: $hover;
            }
        }
    }

    .post-feedback {
        div {
            display: inline-block;

        }

        .views-count {
            margin-left: 12px;
        }
    }

    .post-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px dashed $dark_2;

        p {
            margin-bottom: 0;
            font-size: 14px;
        }

        i {
            color: $hover;
            font-size: 1rem;
            margin-right: 2px;
        }
    }

    .post-date {
        display: inherit;
    }

}

.blog-details-wrapper {
    .single-blog .card>.post-footer {
        border: none;
    }

    .general-nav {
        li {
            margin-bottom: 10px;

            i {
                color: $hover;
                font-size: 1.25rem;
                margin-right: 5px;
                top: 3px;
                position: relative;
            }
        }
    }

    .social-profiles-2 ul li a {
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 13px;

        i {
            margin: 0;
            font-size: 13px;
        }

    }
}

.post-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin-bottom: 0;
    }

    i {
        color: $hover;
        padding-right: 5px;
    }

    font-family: $second_font;
}

.post-lists {
    @include desktop {
        margin-top: 20px;
    }

    .single-blog {
        display: flex;
        margin-bottom: 6px;

        @include mobile {
            display: block;
            margin-bottom: 40px;
        }

        .post-thumb {
            margin-right: 25px;

            img {
                width: 170px;

                @include mobile {
                    width: 100%;
                    height: 200px;
                }
            }
        }

        .post-body {
            padding-top: 5px;
        }
    }
}

.clients-reviews-area {
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
        background-position: 20% 0;
    }

}

.blog-area {
    .card-img-top {
        border-radius: 15px;
    }
}

/*****************************************
09. Apps Features Area
****************************/
.feature-thumb {
    padding-top: 46px;
    width: 107%;
    left: -28px;
    position: relative;

    @include mobile {
        left: auto;
        width: auto;
    }
}

.download-links {
    margin-top: 40px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            position: relative;
            padding: 10px 50px 10px 50px;
            border-radius: 15px;
            box-shadow: 5px 5px 15px 0px rgba(255, 109, 26, .5);

            @include desktop {
                padding: 10px 40px 10px 40px;
            }

            @include mobile {
                padding: 10px 18px 10px 45px;

            }

            a {
                display: inline-block;
                padding-left: 10px;

                i {
                    position: absolute;
                    left: 17px;
                    top: 25%;
                    font-size: 2.5rem;
                }

                span {
                    display: block;
                    font-size: 15px;
                    font-weight: 400;

                }

                &:hover {
                    text-decoration: none;
                }

                font-weight: 600;
            }

            &.apple-store {
                @extend .gradient-bg;
                color: $white;
                margin-right: 20px;

                a {
                    color: $white;
                }
            }

            &.play-store {
                background-color: $white;
                border: 1px solid $hover;

                a {
                    color: $hover;
                }
            }
        }
    }
}

.features-list {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-weight: 600;
            color: $default_color;
            font-size: 20px;
            padding: 10px 0;

            &:last-child {
                padding-bottom: 0;
            }

            span {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 50%;
                text-align: center;
                @extend .gradient-bg;
                color: $white;
                margin-right: 12px;
            }
        }
    }
}

/**********************************
10. Subscribe Area
************************/
.subscribe-area {
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
        margin-left: 15px;

        @include large {
            font-size: 32px;
        }

        @include desktop {
            font-size: 32px;
        }
    }

    .subscribe-form {
        padding-left: 50px;

        @include large {
            padding-left: 0;

        }

        @include desktop {
            padding-left: 0;
            max-width: 65%;
        }

        @include mobile {
            padding-left: 0;
        }
    }
}

.subscribe-title {
    i {
        font-size: 45px;

        @include desktop {
            font-size: 35px;
        }

        @include tablet {
            font-size: 30px;
        }

        @include mobile {
            font-size: 30px;
        }
    }
}

.subscribe-form {
    input[type="email"] {
        border-radius: 25px;
        height: 50px;
        border: 0;
        padding: 10px 20px;

        &:focus {
            box-shadow: none;
        }
    }

    form {
        position: relative;
    }

    button[type="submit"] {
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
        background-color: $default_color;
        height: 100%;
        color: $white;
        padding: 0 30px;
        border-radius: 30px;
        font-size: 14px;

        &:focus {
            outline: 0;
        }
    }
}

.single-gallery {
    display: inline-block;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    margin: 5px 7px;
    z-index: 1;

    a {
        display: block;
        position: relative;
        @extend .black-overlay;
        transition: all .4s linear 0s;

        &::after {
            position: absolute;
            content: "\f16d";
            font-family: "Font Awesome 5 Brands";
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 19px;
            z-index: 1;
            @include opacity(0);

            transition: all .4s linear 0s;
        }
    }

    &:hover a::after {
        @include opacity(1);
    }
}

/******************************
11. Footer Area
*************************/

.footer-wrapper {
    background: #282932;
    @extend .overly;

    &::before {
        background: url('../images/f-loc-left.png')no-repeat bottom left;
        width: 303px;

    }

    &::after {
        position: absolute;
        right: 0;
        top: -60px;
        content: "";
        background: url('../images/f-loc-right.png')no-repeat top right;
        width: 303px;
        height: 100%;
        z-index: -1;

    }
}

.footer-widget.link-widget {
    padding-left: 35px;

    @include desktop {
        padding-left: 0;
    }

    @include mobile {
        padding-left: 0;
    }

    @include tablet {
        padding-left: 0;
    }
}

.copyright-text {
    color: $white;

    a {
        color: $hover;
    }

    p {
        margin-bottom: 0;
    }
}

/********************************
12. About Us Page
********************************/
.page-header-wrapper {
    background-size: cover;
    position: relative;
    background-positon: center top;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($white, .8);
        width: 100%;
        height: 100%;
    }
}

.page-header-2 {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    background-repeat: no-repeat;
    background-position-x: 350px;

    @include large {
        background-position-x: 240px;
        background-size: contain;
    }

    @include desktop {
        background-position-x: 170px;
        background-size: contain;
    }

    @include lg_device {
        background-position-x: 590px;
    }
}

.page-header-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 460px;
    padding-top: 90px;

    @include mobile {
        height: 300px;
        padding-top: 60px;
    }

    @include desktop {
        height: 350px;
        padding-top: 50px;
    }

    .heading-one {
        font-family: $second_font;
    }
}

.page-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            color: #fd831e;
            font-weight: 500;

            a {
                color: #fd831e;
            }
        }
    }
}

.about {
    .post-thumb {
        @extend .overly;
        padding-right: 40px;

        @include desktop {
            padding-right: 20px;
        }

        @include mobile {
            padding-right: 0;
            padding-left: 10px;
        }

        &::before {
            background: url('../images/ss.jpg')no-repeat left center;

            @include large {
                left: -55px;
            }

            @include desktop {
                left: -55px;
            }

            @include mobile {
                left: -50px;
            }
        }

        img {
            border-radius: 20px;
        }
    }
}

.post-content {
    .section-title-3 {
        margin-bottom: 35px;
    }
}

.video-area-2 {
    @include mobile {
        overflow: hidden;
    }

    .video-wrapper {
        position: relative;
        left: 6%;
        width: 112%;
        top: 0;
        height: 490px;
        margin-top: -90px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-size: cover;

        @include large {
            width: 110%;
        }

        @include mobile {
            width: auto;
            margin-top: 0px;
            height: 350px;
        }

        img {
            height: 100%;
        }
    }

    .post-text ul {
        list-style: none;
        padding: 0 0 0 10px;

        li {

            font-weight: 500;
            line-height: 1.3;
            padding-bottom: 18px;

            i {
                color: $hover;
                margin-right: 7px;
                font-size: 18px;
                position: relative;
                top: 1px;
            }
        }
    }

}

.video-block {
    position: absolute;
    left: -35px;
    top: 50%;
    z-index: 1;
    width: 90px;
    height: 90px;
    transform: translateY(-50%);

    .waves {
        position: absolute;
        width: 140px;
        height: 140px;
        right: -25px;
        bottom: -25px;
    }

    .video {
        height: 90px;
        width: 90px;
        text-align: center;
        border-radius: 100%;
        color: #fff;
        display: table;
        background: $hover;
        z-index: 999;

        i {
            display: table-cell;
            vertical-align: middle;
            font-size: 35px;
        }
    }

}

.waves {
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(244, 70, 47, 0.4);
    @include opacity(0);
    border-radius: 100%;
    right: -50px;
    bottom: -50px;
    z-index: -1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
}

@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }

    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}



.video-wrapper {
    a {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

/*************************************
13. Services Page
*************************************/

.services-area {
    .single-service {
        transition: all .4s linear 0s;
        min-height: 250px;
        @extend .overly;
        overflow: hidden;
        border-radius: 20px;
        cursor: pointer;
        margin-bottom: 30px;

        h5 {
            margin-bottom: 20px;
        }

        .service-icon {
            margin: 0 auto;
        }

        &::before {
            background: url('../images/service-cbg.png')no-repeat bottom right;
            background-size: contain;
            top: 125px;
            left: 120px;
            @include opacity(0);
            @include transition();
        }

        &:hover,
        &.active {
            box-shadow: 0 5px 25px rgba(0, 0, 0, .15);

            &::before {
                @include opacity(1);
            }

            .heading-5 a {
                color: $hover;
            }

            .service-icon {
                border-color: $hover;
            }
        }
    }
}

.single-service {
    .service-icon {
        flex: 0 0 80px;
        max-width: 80px;
        height: 80px;
        line-height: 80px;
        margin-right: 25px;
        text-align: center;
        border: 1px solid $default_color;
        border-radius: 50%;
        transition: all .4s linear 0s;
    }
}

.services-wrapper {
    .single-service {
        &:hover {
            .service-icon {
                border-color: $hover;
            }
        }
    }
}

.service-image .half-column-thumb {
    margin-top: -150px;
    margin-bottom: -50px;

    @include desktop {
        margin-bottom: 30px;
    }

    @include mobile {
        margin-bottom: 30px;
        margin-top: -80px;
    }
}


/*************************
14. Menu Page
***************************/
.filter-form {
    margin-top: 15px;

    .snap-label {
        margin-top: 10px;
        font-weight: 600;
        color: $dark;
    }

    .form-group {
        margin-top: 35px;
    }

    .noUi-horizontal {
        height: 8px !important;
        border: 0px !important;
        background: #d7d7d7 !important;
        border-radius: 15px;

        .noUi-handle {
            height: 18px;
            width: 18px;
            border: 3px solid #000;
            background: #ffffff;
            border-radius: 50%;
        }
    }
}

.filter-form .noUi-handle:after,
.noUi-handle:before {
    height: 0px;
    width: 0px;
    background: transparent;
}

.filter-form .noUi-connect {
    background: #ff6e1a;
}

.search-form {
    position: relative;

    .input-group {
        border-radius: 50px;
        overflow: hidden;
    }

    input[type="email"] {
        height: 45px;
        padding-left: 20px;
        border: none;

        &:focus {
            box-shadow: none;
        }
    }

    button[type="submit"] {
        border: 0;
        color: $white;
        background-color: $default_color;
        border-radius: 50%;
        position: absolute;
        right: 0;
        height: 45px;
        width: 45px;
        z-index: 9;
    }
}

.pagination-container {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-table;
            width: 50px;
            height: 50px;
            font-weight: 600;
            margin: 0 5px;
            @include transition();

            a {
                background-color: $dark_4;
                display: table-cell;
                vertical-align: middle;
                border-radius: 50%;
                font-size: 20px;
                color: $dark;
                font-weight: 600;

                i {
                    color: $hover;
                }
            }

            &.active a {
                background-color: $hover;
                color: $white;
                box-shadow: 0px 5px 15px rgba(255, 109, 26, .5);
            }

            &:hover a {
                text-decoration: none;
                background-color: $hover;
                color: $white;
                box-shadow: 0px 5px 15px rgba(255, 109, 26, .5);

                i {
                    color: $white;
                }
            }
        }
    }
}

/**************************
15. Product Details Page
***************************/
.product-thumbs {
    .tab-content img {
        border-radius: 1.25rem;
    }

    ul.nav {
        border: none;
        box-shadow: none;

        .nav-item {
            max-width: 33%;
        }

        .nav-link {
            padding: 16px 4px;
            border: none;

            &.active,
            &:hover,
            &:focus {
                border: none;
            }
        }
    }
}

.product-details {
    h2 {
        font-size: 2.188rem;
    }
}

.product-action {
    form {
        input[type="number"] {
            height: 40px;
            width: 90px;
            border-radius: 50px;
            padding: 15px;
            font-weight: 600;

            &:focus {
                outline: 0;
            }
        }
    }
}

.product-content {
    padding-left: 25px;

    .product-rating {
        p {
            color: $hover;
            margin-bottom: 0;

            span {
                color: $default_color;
            }
        }
    }

    .product-price {
        margin: 20px 0;

        p {
            margin-bottom: 0;
            font-size: 2.3rem;
            font-weight: 600;
            color: $hover;
            padding: 5px 0;
        }
    }

    .product-action {
        .default-btn {
            padding: 10px 25px;
        }

        input {
            border: 1px solid $dark_5;
        }
    }
}

.nav-tabs {
    li {
        a {
            font-size: 1.5rem;
            color: $default_color;

            &:hover {
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-left-color: transparent !important;
            }

            &.active {
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-left-color: transparent !important;
                color: $default_color !important;
                border-bottom-color: $hover !important;
            }
        }
    }
}

.reviews-info {
    .nav-tabs {
        li {
            margin-right: 1.25rem;

            a {
                padding-left: 0;
                font-weight: 700;
                text-transform: capitalize;
                font-size: 1.563rem;
                font-family: $second_font;

                &:hover,
                &.active {
                    color: $hover;
                    border-bottom: 2px solid $hover;
                }
            }
        }
    }

    .tab-content {
        padding-top: 1.25rem;
    }

}

/**********************************
16. Blog Details
***********************************/
.author-thumb {
    width: 30%;
    margin-right: 15px;
}

.date {
    font-family: $second_font;

    p {
        margin-bottom: 5px;
    }
}

.comment-text {
    p {
        margin-bottom: 0;
    }
}

.comments-form {
    input {
        height: 50px;
        border-radius: 30px;
        padding: 15px;
    }

    textarea {
        border-radius: 15px;
        padding: 20px;
    }
}

.comments-form {
    .form-control {
        border: none;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, .1);
        font-size: 15px;
        padding-left: 25px;
    }
}

.sample-icon {
    min-width: 70px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: 1px solid $default_color;
    border-radius: 50%;

    i {
        font-size: 1.4rem;
        color: $hover_3;
    }
}

.contact-info {
    p {
        margin-bottom: 0;
    }
}

.contact-wrapper {
    .single-contact {
        min-height: 200px;
        padding: 30px;
        cursor: pointer;
        transition: .3s ease-in;

        @include desktop {
            margin-bottom: 30px;
            max-width: 60%;
        }

        @include mobile {
            margin-bottom: 30px;
        }

        &:hover {
            transform: translateY(-10px);
        }

        .sample-icon {
            min-width: 60px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            margin-right: 15px;
        }

        .default-btn {
            padding: 8px 30px;
        }
    }
}

.widget-text {
    overflow-wrap: break-word;
}


/**************************
17. Checkout Page
****************************/
.sidebar-wrapper-2 {
    .widget-header {
        border-bottom: 1px dashed $dark;
    }

    .pricing-info {
        select {
            display: inline-block;
        }

        p {
            display: inline-block;
            margin-bottom: 0;
        }
    }

    .pay-form {
        .pay-list {
            background: #fafafa;
            padding: 20px;
            border-radius: 15px;
            margin-bottom: 30px;

        }

        button {
            box-shadow: 0px 5px 25px rgba(255, 109, 26, .4);
        }
    }

    .promo-widget {
        p {
            font-size: 1.2rem;
        }
    }

    .pricing-form {
        .input-group.mt-3 input {
            padding: 9px;
        }
    }

    .single-pricing {

        i {
            color: $hover;
        }

        .price {
            color: $hover;
            padding: 0 5px;
        }

        select {
            width: 50px;
            border-radius: 30px;
            padding: 0 5px;
            border-color: $dark_6;
            color: $dark_3;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .delete-btn {
        i {
            color: $dark_6;
        }
    }

    .btn-default {
        border: 0;
        background-color: $hover;
        padding: 10px 0;
        border-radius: 30px;
        color: $white;
        font-weight: 600;
    }
}
.pricing-widget{
    .wid-title p{
        color: $dark;
        i{
            color: $hover
        }
    }
}
.payment-card-form {
    margin-top: 35px;
}
.pay-list {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            font-weight: 600;

            &:last-child {
                border-top: 1px dashed $dark;
                font-size: 1.3rem;
                padding: 10px 0;
            }
        }
    }
}

.pricing-form {
    form {
        position: relative;

        .btn-default {
            position: absolute;
            right: 0;
            padding: 0 20px;
            z-index: 9;
            height: 100%;
            top: 0;
        }

        input {
            border-radius: 30px !important;
        }
    }
}

.supported-cards {
    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: center;

        li {
            &:first-child {
                padding-left: 0;
            }

            padding: 0 5px;

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.payment-card-form {
    form {
        input[type='text'] {
            border-radius: 30px;
            height: 45px;
        }
    }
}

.tabs-lists ul.nav {
    @extend .menu-tab.nav-pills;

    a .tab-icon {
        width: 52px;
        height: 52px;

        img {
            height: auto;
        }
    }
}

.payment-card-form button {
    font-family: $default_font;
}

.delivery-address .single-contact {
    max-width: 100%;

    @include desktop {
        padding: 15px !important;
    }
}

/********************************
18. Track Order
*********************************/
.delivery-position {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 40%;
        width: 97.7%;
        height: 2px;
        border: 1px dashed $black;
        z-index: 2;
    }

    .single-position {
        position: relative;
        z-index: 3;
        background-color: $white;

        &.active {
            .item-icon {
                border-color: $hover;
            }

            .heading-6 {
                color: $hover;
            }

        }
    }
}

.delivery-position {
    .item-icon {
        width: 130px;
        height: 130px;
        line-height: 120px;
        text-align: center;
        border: 1px solid $default_color;
        border-radius: 50%;
    }
}

.gmap-area {
    iframe {
        width: 100%;
        height: 380px;
    }
}

/*******************************
19. Account Page
*******************************/
.single-order {
    @include mobile{
        display: block !important;
        .order-thumb.me-3 {
            margin: 0 0 20px;
        }
    }
    p {
        color: #5a5b67;
        i {
            color: $hover;
        }
    }
    .post-cn{
        color: #0c0f2e;
    }
    .order-action{
        align-items: center;
        padding-top: 20px;
        .action-btns a {
            padding: 7px 28px;
        }
    }
}

.orders-history {
    .order-action {
        padding-top: 15px;
        border-top: 1px dashed $dark;
    }

    .order-info {
        p {
            margin-bottom: 5px;
        }
    }
}
.summery {
    table{
        tbody, td, tfoot, th, thead, tr {
            border-style: dashed;
        }
    }
}
.odser-details {
    @extend .summery;
}
thead.thead-custom {
    border-top: 1px solid white;
}
/***********************************
20. Login Page
**************************************/
.login-form-wrapper {
    min-height: 100vh;
    @extend .overly;
    &::before{
        width: 50%;
        left: auto;
        right: 0;
        background-image: url(../images/half_column_bg.jpg);
        background-size: cover;
        background-position: center;
        @include mobile{
            background-image: none; 
            width: 0;
            
        }
    }
    @include mobile{
        overflow: hidden;
        .col-md-6.half-column{
            display: none !important;
        }
    }
}
.start-page {
    position: relative;
}
.login-form{
    .section-title-3{
        h2{
            font-size: 45px;
        }
        p{
            font-weight: 600;
            font-size: 18px;
        }
    }
    .user-account-info{
        font-weight: 500;
    }
}

.half-column {
    display: flex;
    align-items: center;
    height: 100vh;

    .half-full {
        max-width: 70%;
        margin: 0 auto;

        @include desktop {
            max-width: 90%;
        }

        @include tablet {
            max-width: 90%;
        }

        @include mobile {
            max-width: 95%;
        }
    }
}
.login-form-wrapper{
    .half-column .half-full {
        max-width: 85%;
        margin-right: 0;
        text-align: center;
        margin-left: auto;
    }
}
.copyright-area-2 {
    position: absolute;
    bottom: 0;
}

.login-form {
    .form-control {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $default_color;
        padding-left: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    label {
        margin-bottom: 0;
        color: $black_3;
        &.form-check-label {
            color: $default_color;
            font-weight: 500;
        }
    }

    .form-check {
        a {
            color: $black_3;
            font-weight: 500;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.copyright-text-2 {
    a{
        color: $hover;
    }
}
.error-content {
    @include mobile{
        padding-top: 50px;
    }
}