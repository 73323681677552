
$lg_device: 1400px;
$xl-large-device: 1285px;
$large-device: 992px;
$desktop-device: 991px;
$tablet-device: 768px;
$mobile-device: 575px;

@mixin lg_device {
    @media (min-width: #{$lg_device}) {
      @content;
    }
  }
@mixin xl_large {
  @media (min-width: #{$xl-large-device}) {
    @content;
  }
}

@mixin large {
    @media (min-width: #{$large-device}) and (max-width: 1200px){
      @content;
    }
  }
@mixin desktop {
  @media (min-width: #{$tablet-device}) and (max-width: #{$desktop-device}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-device}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-device}) {
    @content;
  }
}