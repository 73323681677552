@import "./media_query";
@import "./variables";
@import "./prefix";


/*******************
Heading
********************/
body {
    font-family: $default_font;
    font-size: 15px;
    color: $dark;
    line-height: 1.5rem;
    font-weight: 400;

}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: $default_color;
}

@include xl_large {
    .container {
        max-width: 1170px;
    }
}
a {
    @include transition();
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

.heading-one {
    font-size: 4.5rem;
    font-weight: 800;
    color: $default_color;

    @include desktop {
        font-size: 3.5rem;
    }

    @include tablet {
        font-size: 3rem;
    }

    @include mobile {
        font-size: 2.5rem;
    }
}

.heading-one-alt {
    font-size: 3.5rem;
    font-weight: 700;
    color: $default_color;

    @include desktop {
        font-size: 3.5rem;
    }

    @include tablet {
        font-size: 2.6rem;
    }

    @include mobile {
        font-size: 2rem;
    }

    span {
        color: $hover;
    }
}

.heading-6 {
    font-size: 1.375rem;
    font-weight: 600;
    transition: all .4s linear 0s;
    color: $default_color;

    a {
        color: $default_color;

        &:hover {
            text-decoration: none;
            color: $hover;
        }
    }

}

.heading-5 {
    font-size: 1.3rem;
    font-weight: 600;
    transition: all .4s linear 0s;
    color: $default_color;

    @include tablet {
        font-size: 1.2rem;
    }

    @include mobile {
        font-size: 1.1rem;
    }

    a {
        color: $default_color;

        &:hover {
            text-decoration: none;
            color: $hover;
        }
    }

}

.heading-4 {
    font-size: 1.5rem;
    font-weight: 600;
    transition: all .4s linear 0s;
    color: $default_color;

    @include desktop {
        font-size: 1.3rem;
    }

    @include tablet {
        font-size: 1.2rem;
    }

    @include mobile {
        font-size: 1.1rem;
    }

    a {
        color: $default_color;

        &:hover {
            text-decoration: none;
            color: $hover;
        }
    }

}

.heading-2 {
    font-size: 1.9rem;
    font-family: $second_font;
    font-weight: 700;

    @include desktop {
        font-size: 1.6rem;
    }

    @include tablet {
        font-size: 1.5rem;
    }

    @include mobile {
        font-size: 1.3rem;
    }
}

.heading-2-extra {
    font-size: 2.8rem;
    font-weight: 700;
    font-family: $second_font;
    color: #0c0f2e;
    padding-bottom: 10px;
    line-height: 55px;

    @include large {
        font-size: 2.6rem;
    }

    @include desktop {
        font-size: 2.4rem;
        line-height: 1.3;
    }

    @include tablet {
        font-size: 2.3rem;
    }

    @include mobile {
        font-size: 2.2rem;
    }
}

.active-heading {
    font-size: 2rem;
    font-family: $second_font;
    color: $hover;
    font-weight: 600;

    @include desktop {
        font-size: 1.2rem;
    }

    @include tablet {
        font-size: 1.2rem;
    }

    @include mobile {
        font-size: 1rem;
    }
}

.special-heading {
    font-size: 1rem;
    font-family: $second_font;
    font-weight: 600;

    @include tablet {
        font-size: .6rem;
    }

    @include mobile {
        font-size: .6rem;
    }
}

.heading-6-alt {
    font-size: 1.1rem;
    font-weight: 600;
    color: $default_color;
    font-family: $second_font;
}

.overly {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.widget-title {
    display: inline-block;
    position: relative;
    color: $white;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 35px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        height: 3px;
        background-color: $hover;
        width: 25px;
        border-radius: 50px;
    }
}

.footer-logo {
    margin-bottom: 16px;
}

/***********************
Padding
***********************/
.section-ptb {
    padding-top: 100px;
    padding-bottom: 100px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include mobile {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.section-ptb-2 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section-pt {
    padding-top: 100px;

    @include tablet {
        padding-top: 80px;
    }

    @include mobile {
        padding-top: 80px;
    }
}

.section-pb {
    padding-bottom: 100px;

    @include tablet {
        padding-bottom: 80px;
    }

    @include mobile {
        padding-bottom: 80px;
    }
}

.pt-xs-0 {
    @include tablet {
        padding-top: 0 !important;
    }

    @include mobile {
        padding-top: 0 !important;
    }
}

.p-xs-2 {
    @include mobile {
        padding: 1rem !important;
    }
}

.p-xs-1 {
    @include mobile {
        padding: .5rem !important;
    }
}

.shadow {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1) !important;
}

/********************
section
********************/
.section-title {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    width: 100%;

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        width: 120px;
        height: 5px;
        background-image: url(../images/border.png);
        background-repeat: no-repeat;
        transform: translateX(-50%);
    }
}

.section-title-2 {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    width: 100%;

    &:after {
        content: "";
        position: absolute;
        left: 65px;
        width: 120px;
        height: 3px;
        background-color: $hover;
        transform: translateX(-50%);
        bottom: 0;
    }

    &:before {
        content: "";
        position: absolute;
        left: 80px;
        width: 10px;
        height: 3px;
        background-color: $white;
        transform: translateX(-90%);
        bottom: 0;
        z-index: 11;
    }

    h2 {
        display: inline-block;
        position: relative;
        padding-bottom: 5px;

        &:after {
            content: "";
            position: absolute;
            left: 18%;
            width: 8px;
            height: 3px;
            background-color: $white;
            bottom: -8px;
            z-index: 10;
        }
    }
}

.section-title-3 {
    display: block;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 15px;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 5px;
        height: 5px;
        width: 100%;
        background-image: url(../images/border.png);
        background-repeat: no-repeat;
    }
}

.widget-title-2 {
    position: relative;
    padding-left: 15px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 80%;
        width: 3px;
        background-color: $hover;
    }

    border-bottom: 1px dashed $dark;

    h4 {
        font-family: $second_font;
        font-weight: 700;
    }
}

/*******************
background
********************/

.bg-semi-white {
    background-color: $semi_white;
}

.bg-hover {
    background-color: $hover;
}

.bg-dark-3 {
    background-color: $dark_3;
}

.bg-dark-4 {
    background-color: $dark_4;
}

.bg-black-2 {
    background-color: $black_2;
}

.half-bg {
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: left;
}

.bg-hover-2 {
    background-color: $hover_2;
}

.white-ovelay {
    display: inline-block;
    position: relative;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($white, .45);
        width: 100%;
        height: 100%;
    }
}

.gradient-bg {
    background: $hover;
    background: linear-gradient(0deg, rgba(255, 109, 26, 1) 50%, rgba(253, 131, 30, 1) 100%);
}

/******************
Buttons
*******************/
.default-btn {
    display: inline-block;
    background: $hover;
    background: linear-gradient(0deg, rgba(255, 109, 26, 1) 50%, rgba(253, 131, 30, 1) 100%);
    color: $white;
    padding: 15px 30px;
    border-radius: 30px;
    border: 0;
    font-weight: 500;
    font-size: 1rem;

    i {
        color: $white;
    }

    @include desktop {
        padding: 12px 18px;
        font-size: .95rem;
    }

    @include tablet {
        padding: 14px 20px;
        font-size: .95rem;
    }

    @include mobile {
        padding: 10px 20px;
        font-size: .95rem;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $white;
        text-decoration: none;
    }
}

.default-btn-alt {
    display: inline-block;
    background-color: $white;
    color: $hover;
    padding: 15px 30px;
    border-radius: 30px;
    border: 1px solid $hover;
    font-weight: 600;

    i {
        color: $hover;
    }

    @include desktop {
        padding: 12px 18px;
        font-size: .85rem;
    }

    @include tablet {
        padding: 10px 20px;
        font-size: .65rem;
    }

    @include mobile {
        padding: 10px 20px;
        font-size: .65rem;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        background-color: $hover;
        color: $white;
        text-decoration: none;

        i {
            color: $white;
        }
    }
}

.default-btn-2 {
    display: inline-block;
    background-color: $default_color;
    color: $white;
    padding: 15px 30px;
    border-radius: 30px;
    border: 0;

    @include desktop {
        padding: 15px;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $white;
        text-decoration: none;
    }
}

.default-btn-3 {
    display: inline-block;
    background-color: $dark_4;
    color: $default_color;
    padding: 10px 20px;
    border-radius: 30px;
    border: 0;
    font-weight: 500;
    transition: all .4s linear 0s;
    font-family: $second_font;

    @include desktop {
        padding: 15px;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $white;
        text-decoration: none;
        background-color: $hover;
    }
}

.default-btn-3-alt {
    display: inline-block;
    background-color: $hover;
    color: $white;
    padding: 10px 20px;
    border-radius: 30px;
    border: 0;
    font-weight: 500;
    transition: all .4s linear 0s;
    font-family: $second_font;

    @include desktop {
        padding: 15px;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $white;
        text-decoration: none;
        background-color: $default_color;
    }
}

.sm-btn {
    display: inline-block;
    background-color: $hover;
    color: $white;
    padding: 8px 20px;
    border-radius: 30px;
    transition: all .4s linear 0s;
    border: 1px solid transparent;

    &:hover {
        text-decoration: none;
        background-color: $white;
        color: $hover;
        border: 1px solid $default_color;
    }
}

.sm-btn-2 {
    color: $hover;
    background-color: $hover_2;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 500;
    transition: all .4s linear 0s;

    i {
        padding-right: 5px;
    }

    &:hover {
        color: $white;
        background-color: $hover;
        text-decoration: none;
    }
}

.big-btn {
    width: 100%;
    margin: 0 auto;

    a {
        display: block;
        width: 100%;
        text-align: center;
        color: $hover;
        font-weight: 600;
        font-size: 1.1rem;

        &:hover {
            text-decoration: none;
        }

        i {
            font-size: 1.5rem;
            padding-right: 5px;
        }
    }
}

.g-link {
    display: inline-block;
    background-color: $g_color;
    padding: 10px 40px;
    border-radius: 30px;
    color: $white;
    font-weight: 500;
    font-size: 1.1rem;

    &:hover {
        text-decoration: none;
        color: $white;
    }
    @include desktop{
        margin: 0 0 20px 0;
    }
}

.f-link {
    display: inline-block;
    background-color: $f_color;
    padding: 10px 40px;
    border-radius: 30px;
    color: $white;
    font-weight: 500;
    font-size: 1.1rem;

    &:hover {
        text-decoration: none;
        color: $white;
    }
}

/*************************
social-profiles
*************************/
.social-profiles {
    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                display: inline-block;
                color: $white;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background: $dark_3;
                text-align: center;
                border-radius: 50%;
                font-size: .9rem;
                transition: all .4s linear 0s;
                font-size: 12px;

                &:hover {
                    background: $hover;
                }
            }
        }
    }
}

.social-profiles-2 {
    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;

            a {
                display: inline-block;
                color: $hover;
                height: 45px;
                width: 45px;
                line-height: 45px;
                background-color: $hover_2;
                text-align: center;
                border-radius: 50%;
                font-size: .9rem;
                transition: all .4s linear 0s;

                &:hover {
                    color: $white;
                    background-color: $hover;

                    i {
                        color: $white;
                    }
                }
            }
        }
    }
}

/**********************
Lists
***********************/
.general-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            a {
                display: inline-block;
                padding: 5px 0;
                color: $white;
                transition: all .4s linear 0s;

                &:hover {
                    text-decoration: none;
                    color: $hover;
                }
            }

            &:first-child {
                a {
                    padding-top: 0;
                }
            }

            &:last-child {
                a {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.general-nav-2 {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            color: $white;
            padding: 8px 0;
            display: flex;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            a {
                color: $white;

                &:hover {
                    color: $hover;
                }
            }

            i {
                padding-right: 5px;
                color: $hover;
                margin-right: 8px;
                padding-top: 7px;
            }
        }
    }
}

/* Sidebar Widget */
.widget-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            a {
                display: inline-block;
                padding: 5px 0;
                color: $dark;
                transition: all .4s linear 0s;
                font-weight: 500;

                i {
                    padding-right: 5px;
                    color: $hover;
                }

                &:hover {
                    text-decoration: none;
                    color: $hover;
                    padding-left: 10px;
                }
            }
        }
    }
}

.feature-products {
    .single-feature {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        .figure-thumb {
            margin-bottom: 0;

            img {
                border-radius: 15px;
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
            }
        }

        .sf-text {
            margin-left: 15px;

            h4 {
                margin-bottom: 5px;
                line-height: 20px;
                font-weight: 600;
                font-size: 15px;

                a {
                    color: $dark;
                }

            }

            .sf-meta {
                a {
                    margin-right: 10px;
                    font-size: 14px;
                    color: #979797;

                    &:hover {
                        color: $hover;
                    }
                }
            }
        }

        &:hover {
            h4 a {
                color: $hover;
            }
        }
    }
}

.general-nav-3 {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 15px;
            color: $hover;
            background-color: $white_2;
            border-bottom: 1px solid $dark_5;
            font-size: 1.2rem;

            span {
                color: $default_color;
                padding-right: 10px;
            }
        }
    }
}

.special-nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 5px 0;
            font-size: 1.2rem;

            i {
                color: $hover;
            }
        }
    }
}

.bold-font {
    font-weight: 600;
    color: $default_color;
    font-size: 1.1rem;
}

/************************
Margin
***************************/

.mb-xs-5 {
    @include desktop {
        margin-bottom: 2rem;
    }

    @include tablet {
        margin-bottom: 2rem;
    }

    @include mobile {
        margin-bottom: 2rem;
    }
}

.mt-xs-5 {
    @include desktop {
        margin-top: 3rem;
    }

    @include tablet {
        margin-top: 3rem;
    }

    @include mobile {
        margin-top: 3rem;
    }
}

.mt-xs-4 {
    @include tablet {
        margin-top: 2rem;
    }

    @include mobile {
        margin-top: 2rem;
    }
}

.mb-xs-4 {
    @include tablet {
        margin-bottom: 2rem;
    }

    @include mobile {
        margin-bottom: 2rem;
    }
}

.mb-xs-0 {
    @include tablet {
        margin-bottom: 0 !important;
    }

    @include mobile {
        margin-bottom: 0 !important;
    }
}

/*****************************
Colors
*****************************/
.text-hover {
    color: $hover;
}

/***********************
rounded
*************************/
.custom-round {
    border-radius: 15px;
}

.custom-round-2 {
    border-top-left-radius: 20px;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
}

.custom-round-3 {
    border-top-right-radius: 20px;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
}

/***********************
borders
***********************/
.custom-border {
    border: 1px dashed #959595;
    border-radius: 20px;
}

.custom-border-2 {
    border: 1px dashed $hover;
}

.thead-custom {
    th {
        color: $white;
        background-color: $hover;
        border-color: $dark_7;
    }
}

.border-top-0 {
    border-top: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.custom-botder-bottom {
    border-bottom: 1px dashed $dark;
}

/*******************
blockquote
**********************/
.blockquote {
    font-size: 1.25rem;
    font-weight: 700;
    position: relative;
    font-family: $second_font;
    line-height: 30px;
    padding: 45px;
    color: #0c0f2e;
    border-radius: 30px;

    &:before {
        content: "\f10d";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        color: $hover;
        font-size: 60px;
        position: absolute;
        opacity: .2;
        left: 10%;
        top: 35px;
    }

}

/****************************
display
*******************************/
.d-xs {
    @include large {
        display: block;
    }

    @include desktop {
        display: block;
    }

    @include tablet {
        display: block;
    }

    @include mobile {
        display: block;
    }
}

.d-xs-none {

    @include tablet {
        display: none;
    }

    @include mobile {
        display: none;
    }
}

/************************
Overlay
***************************/
.black-overlay {
    display: inline-block;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($black, 0.56);
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all .4s linear 0s;
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
}

a#scrollUp {
    right: 50px;
    bottom: 50px;
    background: $hover;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    color: $white;
    font-size: 22px;
}


.post-comments {
    ol {
        margin: 0;
        list-style: none;
        padding: 45px 0px 30px;


        li {
            margin-bottom: 65px;
            @extend .overly;

            &::before {
                background: #ebebeb;
                height: 1px;
                top: 127%;
            }

            &:first-child::before {
                top: 111%;

                @include mobile {
                    top: 100%;
                }
            }

            article.comment-body {
                position: relative;

                .comment-thumb {
                    float: left;

                    @include mobile {
                        float: none;
                    }
                }

                .comment-details {
                    margin-left: 135px;

                    .comment-name {
                        font-size: 18px;
                        color: $default_color;
                        margin-bottom: 0px;
                        font-weight: 600;
                    }

                    span.date {
                        color: $hover;
                        font-family: $default_font;
                    }

                    p {
                        max-width: 90%;
                        margin: 5px 0 0;
                        line-height: 26px;
                    }

                    .reply-btn {
                        position: absolute;
                        right: -30px;
                        top: 0px;
                        overflow: hidden;
                        padding: 10px 30px;
                    }
                }
            }

            .children {
                padding: 34px 0px 0 75px;

                li::before {
                    display: none;
                }

                .comment-body {
                    .comment-details {
                        margin-left: 135px;
                    }
                }
            }

            &:last-child {
                margin: 0;
            }

            @include mobile {
                margin-bottom: 45px;

                &::before {
                    top: 115%;
                }

                article.comment-body .comment-details {
                    margin: 20px 0 0;
                }

                .children {
                    padding: 30px 30px 60px;

                    .comment-body .comment-details {
                        margin: 20px 0 0;
                    }
                }

            }
        }
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 999999;
}

.preloader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;

    span {
        position: absolute;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: $hover;
        -webkit-animation: preloader 1.3s linear infinite;
        animation: preloader 1.3s linear infinite;

        &:last-child {
            animation-delay: -0.8s;
            -webkit-animation-delay: -0.8s;
        }
    }

}


@keyframes preloader {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes preloader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}