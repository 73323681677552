$white: #fff;
$white_2: #f7f7f7;
$semi_white: #fafafa;
$black: #222222;
$black_2: #373844;
$black_3: #8b8c99;
$hover: #fe771c;
$hover_2: #ffe2d1;
$hover_3: #fc0039;
$g_color: #ea4335;
$f_color: #3b5998;
$semi_hover: #fff6f4;
$dark: #5a5b67;
$dark_2: #cfcfcf;
$dark_3: #53545b;
$dark_4: #e1e1e1;
$dark_5: #e9e9e9;
$dark_6: #cbcbcf;
$dark_7: #dee2e6;
$secondary_color: #7551ec;
$default_color: #0c0f2e;
$default_size: 1rem;
$default_font:  'Inter', sans-serif; 
$second_font: 'Playfair Display', serif;
$btn_padding: 10px 25px;
$bold_weight: 600;